import React from 'react'
import autoBind from 'react-autobind'
import './payment-form.css'

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      total: props.total,
      giftCardBalance: props.giftCardBalance || 0,
      giftcard: props.giftcard || 0,
      taxTotal: 0,
      promoCode: props.promoCode,
      promoCodeError: false,
      discountApplied: props.discount || 0,
      discountedTotal: 0,
      grandTotal: props.total || 0,
      balanceDue: 0,
    };
    autoBind(this);
  }

  componentWillMount() {
    this.setState({
      discountedTotal: 0,
      promotions: {},
    });
  }

  componentDidUpdate(){
    window.affirm.ui.ready(() =>{
      window.affirm.ui.refresh();
    });
  }

  renderDiscountLineItem() {
    const { discount, discountName } = this.props
    return Number(discount) > 0 ?
      <div className="columns with-padding-top-bottom">
        <div className="column is-8">Discount ({discountName})</div>
        <div className="column is-4" style={{textAlign: 'right'}}>- ${discount}</div>
      </div>
      : null
  }

  renderAffirmMessage = (amount) => {
    const orderTotal = amount >= 50 ? Number(amount).toFixed(2) * 100 : amount
    return(
      <div style={{marginTop:'15px'}}>
        <p class="affirm-as-low-as" data-page-type="product" data-amount={orderTotal}></p>
      </div>
    )
  }

  stripeMinCharge() {
    const orderTotal = Number(this.props.total)
    if (orderTotal > 0 && orderTotal < 0.50 && !this.props.giftCardBalance) {
      return <div> ${orderTotal.toFixed(2)}  <p className="warning"><sup>*</sup>&nbsp;Credit card orders will be charged a minimum of $0.50.</p> </div>
    } else if (orderTotal > 0) {
      return <div>${orderTotal.toFixed(2)}</div>
    } else {
      return '$0.00'
    }
  }
  
  stripeMinChargeWithGC() {
    const orderTotal = Number(this.props.total) - Number(this.props.giftcard)
    console.log('ORDER SUMMARY---TOTAL, GIFTCARD', Number(this.props.total), Number(this.props.giftcard) )
    if (orderTotal > 0 && orderTotal < 0.50) {
      return <div> ${orderTotal.toFixed(2)}  <p className="warning"><sup>*</sup>&nbsp;Credit card orders will be charged a minimum of $0.50.</p> </div>
    } else if (orderTotal > 0) {
      return <div>${orderTotal.toFixed(2)}</div>
    } else {
      return '$0.00'
    }
  }

  render() {
    const { shipping, shippingDescription, giftCardBalance, giftcard, taxTotal, promoCode, cart } = this.props;
    return (
      <article className="payment__form">
        <article className="checkout__table__holder">
          <div className="columns with-padding-top-bottom checkout__table checkout__table__header">
            {/* <div className="column is-2 has-text-centered">Item</div> */}
            <div className="column is-6">Name</div>
            <div className="column is-2 has-text-centered">QTY</div>
            <div className="column is-4 has-text-right">Amount</div>
          </div>
          {cart.map((item, index) => {
            if (item && item.qty && item.item_id) {
              return (
                <div className="columns with-padding-top-bottom item_details" key={index}>
                  {/* <div className="column is-2 has-text-centered">
                    <img src={item.photopersonalization.previewUrl} alt={item.name}/>
                  </div> */}
                  <div className="column is-6">{item.name}</div>
                  <div className="column is-2 has-text-centered">{item.qty}</div>
                  <div className="column is-4 has-text-right">${(item && item.total) ? item.total.toFixed(2) : '0.00'}</div>
                </div>);
            } else {
              return null;
            }
          })}
          <div>
            {promoCode ?
              <div>
                {this.renderDiscountLineItem()}
              </div>
              : null}
          </div>
          <div className="columns with-padding-top-bottom checkout__table">
            <div className="column is-8">{shippingDescription}</div>
            <div className="column is-4 bold-primary">{Number(shipping) ? '$' + shipping : 'FREE'}</div>
          </div>

          <div className="columns with-padding-top-bottom checkout__table">
            <div className="column is-8">Estimated Sales Tax</div>
            <div className="column is-4">
              $0
              {/* TAXJAR DEPENDENCIES REMOVED */}
              {/* {(Number(taxTotal)) ?
                <span>
                  ${Number(taxTotal).toFixed(2)}
                </span> :
                <span>$_.__</span>
              } */}
            </div>
          </div>
          <div className="columns checkout__table checkout__table__total">
            <div className="column is-8">Order Total</div>
            <div className="column is-4  bold-primary">
              {this.stripeMinCharge()}
            </div>
          </div>
          {giftcard > 0 ?
            <div>
              <div className="columns checkout__table">
                <div className="column is-8">Gift Card Applied <br />
                  (Remaining balance on Gift Card : ${giftCardBalance})</div>
                <div className="column is-4">-${giftcard}</div>
              </div>
              <div className="columns checkout__table">
                <div className="column is-8">Balance Due</div>
                <div className="column is-4 bold-primary">{this.stripeMinChargeWithGC()}</div>
              </div>
              {this.renderAffirmMessage((Number(this.props.total) - Number(this.props.giftcard)).toFixed(2))}
            </div>
            : this.renderAffirmMessage(this.props.total)}
            {/* {this.renderAffirmMessage(this.props.total)} */}
        </article>
      </article>
    );
  }
}

export default PaymentForm
