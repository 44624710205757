import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import { connect } from 'react-redux'
import { pathOr, path } from 'ramda'
import { update_order_paypal_shipping, is_fetching, smartyStreetsApi, updatePaypalgiftNote, 
  update_paypal_initial_shipping } from '../../actions'
import { message } from 'antd'
import axios from 'axios'

const taxableStates = ['FL', 'IL', 'OK', 'PA', 'WA']
var reason_code;
const local = (window.location.hostname.indexOf("local") > -1);
const staging = local || window.location.hostname.indexOf('staging') > -1;
const develop = (window.location.hostname.indexOf('develop.') > -1)
const API_URL_2 = (local || develop)
  ? 'https://myphoto-api-develop.appspot.com'
  : (staging)
    ? 'https://myphoto-api-staging.appspot.com'
    : 'https://mp-cloud-api.appspot.com'
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
  }
  componentDidMount() {
    const {
      isScriptLoaded,
      isScriptLoadSucceed
    } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }
  componentWillUnmount() {
    delete window.React
    delete window.ReactDOM
  }
  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;
    const isLoadedButWasntLoadedBefore =
      !this.state.showButton &&
      !this.props.isScriptLoaded &&
      isScriptLoaded;
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }
  updatePaypalOrder(result) {
    const { order, user, shipMethod, dispatch, values, autoShipStates, apoStates } = this.props;
    result.purchase_units[0].shipping.address.country_code = undefined
    if (pathOr('US', ['purchase_units', '0', 'shipping', 'address', 'country_code'] , result).toUpperCase() === 'US') {
      // shipping is in the US
      //verify paypal shipping address with smarty streets
      const paypal_state = pathOr('', ['purchase_units', '0', 'shipping', 'address', 'admin_area_1'] , result)
      const paypal_address2 = pathOr('', ['purchase_units', '0', 'shipping', 'address', 'address_line_2'] , result)
      const paypal_shipping = {
        address1: pathOr('',['purchase_units', '0', 'shipping', 'address', 'address_line_1'] , result),
        address2:  paypal_address2.toLowerCase() === 'undefined' ? undefined : paypal_address2,
        city: pathOr(null,['purchase_units', '0', 'shipping', 'address', 'admin_area_2'] , result), 
        state: paypal_state.toLowerCase() === 'undefined' || paypal_state.toLowerCase() === 'null' ? undefined : paypal_state,  
        zip: pathOr(null,['purchase_units', '0', 'shipping', 'address', 'postal_code'] , result),
      }
      dispatch(updatePaypalgiftNote(order.id, values, order.notes)).then(() => { dispatch(is_fetching()) })
      dispatch(smartyStreetsApi(paypal_shipping, 'paypal'))
        .then((res) => {
          const { stateAbbreviated, zipType, recordType, dpvFootNotes } = res
          const POBoxCheck = (recordType === 'P' || zipType === 'POBox' || (['PB', 'P1', 'P3'].includes(dpvFootNotes))) 
          const MilitaryCheck = (zipType === 'Military' || dpvFootNotes === 'F1' || apoStates.includes(stateAbbreviated))
          const stateCheck = autoShipStates.includes(stateAbbreviated)
          reason_code = MilitaryCheck 
                    ? 'DA-ML'
                    : POBoxCheck 
                      ? 'DA-PO' 
                      : !stateCheck
                        ? 'DA-ST'
                        : null
          let new_shipping;
          //TODO: check this condition from paypal res
          if (res !== false && (res.stateAbbreviated.toLowerCase() !== paypal_state.toLowerCase() || res.city.toLowerCase() !== paypal_shipping.city.toLowerCase() || res.zip !== paypal_shipping.zip)) {  // update with smarty streets address if smarty returns true
            const new_state =  path(['stateAbbreviated'] , res)
            new_shipping = [{
              carrier: '',
              name: path(['purchase_units', '0', 'shipping', 'name', 'full_name'] , result)  ? path(['purchase_units', '0', 'shipping', 'name', 'full_name'] , result) : path(['payer', 'name', 'given_name'], result),
              last_name: path(['purchase_units', '0', 'shipping', 'name', 'full_name'] , result)  ? '' : path(['payer', 'name', 'surname'] , result),
              address1: pathOr(null, ['deliveryLine1'] , res),
              address2: pathOr(null, ['deliveryLine2'] , res),
              city:  pathOr(null, ['city'] , res),
              state:  new_state.toLowerCase() === 'undefined' || new_state.toLowerCase() === 'null' ? undefined : new_state,
              zip:  (res.zip && res.zipLastFour) ?  `${res.zip}-${res.zipLastFour}` : (res.zip || null),
              country: pathOr('US', ['purchase_units', '0', 'shipping', 'address', 'country_code'] , res),
              business: false,
              id: pathOr(0, ['shipping', '0', 'id'], order),
              method: shipMethod
            }]
          } else {  // update with paypal shipping address if smarty returns false
            const admin_area_1 = pathOr('', ['purchase_units', '0', 'shipping', 'address', 'admin_area_1'] , result)
            const address_2 = pathOr('', ['purchase_units', '0', 'shipping', 'address', 'address_line_2'] , result)
            if(res === false) reason_code = 'DA-UN'
            new_shipping = [{
              carrier: '',
              name: path(['purchase_units', '0', 'shipping', 'name', 'full_name'] , result)  ? path(['purchase_units', '0', 'shipping', 'name', 'full_name'] , result) : path(['payer', 'name', 'given_name'], result),
              last_name: path(['purchase_units', '0', 'shipping', 'name', 'full_name'] , result)  ? '' : path(['payer', 'name', 'surname'] , result),
              address1: pathOr('', ['purchase_units', '0', 'shipping', 'address', 'address_line_1'] , result),
              address2:  address_2.toLowerCase() === 'undefined' ? undefined : address_2,
              city: pathOr(null, ['purchase_units', '0', 'shipping', 'address', 'admin_area_2'] , result), 
              state:  admin_area_1.toLowerCase() === 'undefined' || admin_area_1.toLowerCase() === 'null'  ? undefined : admin_area_1 , 
              zip: pathOr(null, ['purchase_units', '0', 'shipping', 'address', 'postal_code'] , result), 
              country: pathOr('US', ['purchase_units', '0', 'shipping', 'address', 'country_code'] , result),
              business: false,
              id: pathOr(0, ['shipping', '0', 'id'], order),
              method: shipMethod
            }]
          }
          // add PayPal transcation
          const new_transactions = {
            order_id: order.id,
            type:'payment',
            amount: path(['purchase_units', '0', 'amount', 'value'], result),
            currency: path(['purchase_units', '0', 'amount', 'currency_code'], result),
            processor_id: 0,
            giftcard_code:'',
            payment_method:'creditcard',
            processor_type: 'paypal',
            purchase_order:'',
            last4: '',
            response: result.status,
            customer_id: path(['payer', 'payer_id'], result),
            card_id: '',
            transaction_id: path(['purchase_units', '0', 'payments', 'captures', '0', 'id'] , result),
            transaction_url: '',
            created_at: result.create_time,
          }
          //capture paypal-billing-address
          const new_billing = {
            billing_name: path(['payer', 'name', 'given_name'] , result),
            billing_last_name:  path(['payer', 'name', 'surname'] , result),
            billing_address1: path(['payer', 'address', 'address_line_1'] , result),
            billing_address2: path(['payer', 'address', 'address_line_2'] , result),
            billing_city: path(['payer', 'address', 'admin_area_2'] , result),
            billing_state: path(['payer', 'address', 'admin_area_1'] , result),
            billing_zip:  path(['payer', 'address', 'postal_code'] , result),
            billing_country: path(['payer', 'address', 'country_code'] , result),
          }
          dispatch(update_order_paypal_shipping(order.id, user, new_shipping, new_billing, new_transactions, reason_code))
          dispatch(is_fetching())
        })
    } else {
      // shipping is outside the US
      message.error('Sorry, we only ship to the US 50 states and DC.', 12)
    }
  }

  render() {
    const paypal = window.paypal
    const { order, areFieldsValid, env, commit, client,
      onSuccess, onError, onCancel, style, shipMethod, dispatch, user } = this.props;
    const { showButton } = this.state
    const confirmId = path(['confirm_id'], order)
    const paypalTotal = path(['tax_amount'], order) ? Number(path(['total_amount'], order) - path(['tax_amount'], order)).toFixed(2) : Number(path(['total_amount'], order))
    const newCustomer = {
      customer_email: pathOr('',['email'], user),	     
      customer_name: pathOr('',['name'], user),	  
      customer_last_name: pathOr('',['lastName'], user),	     
      customer_phone: pathOr('',['phone'], user),     
    }
    let ReactButton;
    if (showButton) ReactButton = paypal.Button.driver('react', { React, ReactDOM })
    const payment = () => {
      if (!areFieldsValid) {
        return message.info('Please enter your information', 5) && false
      }
      // 'intent'--- sale || order || authorize
      return window.paypal.rest.payment.create(env, client, {
          transactions: [
            {
              amount: {
                total: paypalTotal,
                currency: 'USD'
              },
              invoice_number: confirmId,
            }
          ],
      })
    }
    const onShippingChange = (data, actions) => {
      // Reject non-US addresses
      if (data.shipping_address.country_code !== 'US') {
        return actions.reject();
      } else {
        // TAXJAR REMOVED --START--
      // update shipping address and dispatch taxrate based on state
      // const paypal_shipping = {
      //   address1: pathOr(null, ['shipping_address', 'line1'], data),
      //   city: pathOr(null, ['shipping_address', 'city'], data),
      //   state: pathOr(null, ['shipping_address', 'state'], data),
      //   zip: pathOr(null, ['shipping_address', 'postal_code'], data),
      //   country: pathOr('US', ['payer', 'payer_info', 'country_code'], data),
      // }
      // if(taxableStates.includes((paypal_shipping.state))) {
        // const discountFix = (order && order.merchandise_amount) ? 1 - Number(order.discount_amount)/order.merchandise_amount : 1
        // const line_items = order.items.map(item => {
        //   let taxCode;
        //   switch(item.sku){
        //     case '10067': // these are giftcard skus which are not taxable
        //     case '10066':
        //     case '10065':
        //     case '10064':
        //       taxCode = '99999'; break;
        //     case '703603': // these are t-shirt skus which have special tax code
        //     case '703602':
        //     case '703601':
        //     case '703600':
        //       taxCode = '20010'; break;
        //     default: // all others have full-tax rate
        //     taxCode = undefined; break;
        //   }
        //   return {
        //     id: item.id,
        //     quantity: 1,
        //     product_tax_code: taxCode,
        //     unit_price: Number(item.total) * discountFix
        //   }
        // })
        // const payload = {
        //   from_country: 'US',
        //   from_zip: '33445',
        //   from_state: 'FL',
        //   from_city: 'Delray Beach',
        //   from_street: '2915 S Congress Ave',
        //   to_country: 'US',
        //   to_zip: paypal_shipping.zip,
        //   to_state: paypal_shipping.state,
        //   to_city: paypal_shipping.city,
        //   to_street: paypal_shipping.address1 ? paypal_shipping.address1 : '' ,
        //   amount: paypalTotal,
        //   shipping: order.shipping_amount,
        //   line_items
        // }
        // return axios.post(`${API_URL_2}/taxrate/paypal`, payload,  { headers: { 'Authorization': this.props.token } })
        //   .then((responseData) => { // Patch the shipping amount
        //         const newSubTotal = Number(Number(paypalTotal) + responseData.data.taxTotal).toFixed(2) // new total with tax
        //         const FinalTotal = path(['giftcard_redeemed'], order)  // calculate total again if using giftcard
        //         ? Number(newSubTotal - path(['giftcard_redeemed'], order)).toFixed(2)
        //         : newSubTotal;
        //         const new_shipping = [{
        //           address1: pathOr(null, ['shipping_address', 'line1'], data),
        //           city: pathOr(null, ['shipping_address', 'city'], data),
        //           state: pathOr(null, ['shipping_address', 'state'], data),
        //           zip: pathOr(null, ['shipping_address', 'postal_code'], data),
        //           country: pathOr('US', ['payer', 'payer_info', 'country_code'], data),
        //           business: false,
        //           id: pathOr(0, ['shipping', '0', 'id'], order),
        //           method: shipMethod
        //         }]
        //         dispatch(is_fetching())
        //         dispatch(update_paypal_initial_shipping(order.id, 'shipping', new_shipping, newCustomer))
        //           return actions.order.patch([  // update paypal subtotal with tax
        //           {
        //             op: 'replace',
        //             path: '/purchase_units/@reference_id==\'default\'/amount',
        //             value: {
        //               currency_code: 'USD',
        //               value: FinalTotal,
        //             }
        //           }
        //         ])
        //   })
        //   .catch((e) => {
        //     console.log('Error updating shipping', e)
        //     return
        //   })
        // TAXJAR REMOVED --END--
      // } else {
        const responseData = {
          taxTotal: 0,
          taxRate: 0
        }
            const newSubTotal = Number(Number(paypalTotal) + responseData.taxTotal).toFixed(2) // new total with tax
            const FinalTotal = path(['giftcard_redeemed'], order)  // calculate total again if using giftcard
            ? Number(newSubTotal - path(['giftcard_redeemed'], order)).toFixed(2)
            : newSubTotal;
            const new_shipping = [{
              address1: pathOr(null, ['shipping_address', 'line1'], data),
              city: pathOr(null, ['shipping_address', 'city'], data),
              state: pathOr(null, ['shipping_address', 'state'], data),
              zip: pathOr(null, ['shipping_address', 'postal_code'], data),
              country: pathOr('US', ['payer', 'payer_info', 'country_code'], data),
              business: false,
              id: pathOr(0, ['shipping', '0', 'id'], order),
              method: shipMethod
            }]
            dispatch(is_fetching())
            dispatch(update_paypal_initial_shipping(order.id, 'shipping', new_shipping, newCustomer))
              return actions.order.patch([  // update paypal subtotal with tax
              {
                op: 'replace',
                path: '/purchase_units/@reference_id==\'default\'/amount',
                value: {
                  currency_code: 'USD',
                  value: FinalTotal,
                }
              }
            ])
      // }
  }
  }

    const onApprove = (data, actions) => {
      dispatch(is_fetching())
      actions.payment.get().then((response) => { //creates payment authorization
        const payment = {
          paid: true,
          cancelled: false,
          payerID: data.payerID,
          paymentID: data.paymentID,
          paymentToken: data.paymentToken,
          returnUrl: data.returnUrl,
        }
        return actions.order.capture().then((details) => { //charges customer and updates order_status to pending
          this.updatePaypalOrder(details)
          onSuccess(payment);
        }).catch((error) => {
          console.log('Error capturing payment from customer', error)
          return
        })
      })
    }
    return (
      <div>
        {showButton && 
          <ReactButton
            env={env}
            client={client}
            commit={commit}
            payment={payment}
            onAuthorize={onApprove}
            onShippingChange={onShippingChange}
            onCancel={onCancel}
            onError={onError}
            style={style}
          />
        }
      </div>
    )
  }
}

const WithScript = scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton)

export default connect()(WithScript);